export const homeObjOne = {
  id: 'about',
  lightBg: true,
  lightText: false,
  lighttextDesc: true,
  topLine: 'Who am I?',
  headline1: 'Software Developer',
  headline2: 'Tech Enthusiast',
  description: `Greetings! I'm Vishnu, a dedicated software developer hailing from Cepheid. With a robust background in Computer Science, I am committed to pushing the boundaries of innovation and delivering creative solutions. My journey through diverse projects has been a continuous learning experience, where both success and failure have served as invaluable teachers.`,

  skills: `🔧 Technical Skills:
  
  Languages: Java, Python, C/C++, SQL, JavaScript, HTML/CSS
  Frameworks: React, Node.js, Flask, Angular, Laravel
  Developer Tools: Docker, PyTorch, Google Cloud Platform, VS Code, Visual Studio, PyCharm, IntelliJ, Eclipse
  Database: MySQL, MongoDB, Spark, Hive, GCP Big Query, GCP Cloud Firestore, and Firebase
  Operating Systems: Linux/Unix, macOS, Windows
  Design: Microsoft Office, PowerBI, Adobe, Tableau, Adobe CC`,

  description2: `In the dynamic world of software engineering, I thrive on creative problem-solving and blend teamwork with individual efforts. My ability to think critically and adapt swiftly, coupled with strong communication skills, makes me an effective collaborator on any project.
  Let's connect and explore the possibilities of collaboration and innovation! 💡`,
  
  buttonLabel: 'Get started',
  imgStart: false,
  img: '/images/svg-1.svg',
  alt: 'Coder',
  dark: true,
  primary: true,
  darkText: true
};
